.sectionMain {
  padding: 60px 0;
  border-radius: 40px;

  @media (min-width: 1080px) {
    padding: 110px 0;
    border-radius: 80px;
  }
}

.sectionMainDark {
  background: #FFFBF0;
  padding: 40px 0;
  overflow: hidden;

  @media (min-width: 1080px) {
    padding: 90px 0;
  }
}

.sectionMain:not([class*=dark]) + .sectionMain:not([class*=dark]) {
  padding-top: 0
}

.sectionMainContainer {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;

  @media (min-width: 1550px) {
    max-width: 1300px;
  }
}

.sectionMainHeader {
  text-align: center;
  margin-bottom: 34px;

  @media (min-width: 1080px) {
    margin-bottom: 60px;
    text-align: left;
    margin-left: 200px;
  }
}

.sectionMainHeadline {
  margin: 0;
  font-weight: 700;
  font-size: 34px;
  line-height: 1.2;
  color: var(--text-base);

  @media (min-width: 1080px) {
    font-size: 50px;
    line-height: 1.4;
  }
}

.sectionMainHeadline br {
  display: initial;

  @media (min-width: 1080px) {
    display: none;
  }
}

.sectionMainHeadline b,
.sectionMainHeadline strong {
  font-weight: 700;
}

.sectionMainText {
  display: flex;
  align-items: center;
  color: var(--blue-grey-color-200);
  font-size: 14px;
  justify-content: center;
  line-height: 1.4;
  font-weight: 400;
  margin-bottom: 12px;
  gap: 12px;

  @media (min-width: 1080px) {
    font-size: 24px;
    justify-content: left;
  }
}

.sectionMainFlag {
  width: 35px;
  height: 26px;
}

.sectionMainFlag img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 4px;
}

.sectionMainFooter {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;

  @media (min-width: 1080px) {
    margin-top: 80px;
  }
}

.sectionMainMore {
  outline: 0;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  border: 0;
  width: 100%;
  font-size: 16px;
  line-height: 22px;
  padding: 16px 0;
  background-color: #4faf4d;
  color: #fff;
  text-decoration: none;
  font-weight: 700;
  letter-spacing: .02em;
  text-transform: uppercase;
  text-align: center;
  border-radius: 35px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media (min-width: 1080px) {
    padding: 22px 58px;
    font-size: 20px;
    line-height: 26px;
  }
}

.sectionMainMore:hover {
  background-color: #53cc51;
}
.sectionMainMore:active {
  background-color: #47a245;
}

.cloudTags {
  position: relative;
}

.cloudTagsPic {
  position: static;
  width: 285px;
  height: 285px;
  margin: 0 auto 50px;

  @media (min-width: 1080px) {
    position: absolute;
    top: -110px;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 465px;
    height: 465px;
    pointer-events: none;
  }
}

.cloudTagsImg {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  border-radius: 50%;
}

.cloudTagsGroup {
  display: block;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  margin-left: -20px;
  margin-right: -20px;
  padding: 0 20px 20px;

  @media (min-width: 1080px) {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: inherit;
  }
}

.cloudTagsGroup:after {
  display: none;

  @media (min-width: 1080px) {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 105%;
    height: 105%;
  }
}

.cloudTagsList {
  flex: 1 1 100%;
  max-width: 100%;
  display: flex;

  @media (min-width: 1080px) {
    flex: 1;
    display: block;
  }
}

.cloudTagsList:not(:last-child) {
  margin-bottom: 10px;

  @media (min-width: 1080px) {
    margin-bottom: 0;
  }
}

.cloudTagsItem {
  position: relative;
  display: flex;
}

.cloudTagsItem:not(:last-child) {
  margin-bottom: 0;
  margin-right: 10px;

  @media (min-width: 1080px) {
    margin-bottom: 70px;
    margin-right: 0;
  }
}

@media (min-width: 1080px) {
  .cloudTagsList:first-child .cloudTagsItem:nth-child(1) {
    margin-left: 225px;
  }
  .cloudTagsList:first-child .cloudTagsItem:nth-child(2) {
    margin-left: 28px;
  }
  .cloudTagsList:first-child .cloudTagsItem:nth-child(3) {
    top: 15px;
  }
  .cloudTagsList:first-child .cloudTagsItem:nth-child(4) {
    margin-left: 130px;
    top: 30px;
  }
  .cloudTagsList:first-child .cloudTagsItem:nth-child(5) {
    margin-left: 385px;
    top: 30px;
  }
  .cloudTagsList:last-child .cloudTagsItem {
    justify-content: flex-end;
  }
  .cloudTagsList:last-child .cloudTagsItem:nth-child(1) {
    margin-right: 180px;
  }
  .cloudTagsList:last-child .cloudTagsItem:nth-child(2) {
    margin-right: 26px;
  }
  .cloudTagsList:last-child .cloudTagsItem:nth-child(4) {
    margin-right: 82px;
  }
  .cloudTagsList:last-child .cloudTagsItem:nth-child(5) {
    margin-right: 255px;
  }
}

.cloudTagsLink {
  font-size: 16px;
  line-height: 20px;
  //border: 3px solid #4faf4d;
  padding: 5px 25px 5px 10px;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;
  letter-spacing: 0.02em;
  font-weight: 700;
  background-color: #4faf4d;
  color: #FFFFFF;
  border-radius: 30px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  cursor: pointer;
  transition: all 150ms ease;
  gap: 12px;

  @media (min-width: 1080px) {
    font-size: 18px;
    line-height: 1;
    border: 4px solid #4faf4d;
    padding: 14px 30px 14px 22px;
  }
}

.cloudTagsGroup::-webkit-scrollbar {
  width: 160px;
  height: 9px;
  background: transparent;

}

.cloudTagsGroup::-webkit-scrollbar-thumb {
  background: #75726B;
  border-radius: 100px;
}

.cloudTagsGroup::-webkit-scrollbar-track {
  background: #F1EFE9;
  border-radius: 100px;
  margin: 80px;
}

.icon {
  background: #FFFFFF;
  border-radius: 50%;
  padding: 4px !important;
}

.cloudTagsLink img {
  width: 20px;
  height: 20px;
  margin-right: 12px;
  display: inline-block;
  -o-object-fit: cover;
  object-fit: cover;

  @media (min-width: 1080px) {
    width: 34px;
    height: 34px;
    margin-right: 10px;
  }
}

.cloudTagsLink span {
  line-height: 34px;
  display: inline-block;

  @media (min-width: 1080px) {
    line-height: 20px;
  }
}

.cloudTagsLink:hover {
  background: url("/images/aboutUs/arrow22.svg") 96% 45% no-repeat ,#1abad9;
  border-color: #1abad9;
  color: #fff;
}
.cloudTagsLink:active {
  background-color: #1fa7c1;
  border-color: #1fa7c1;
  color: #fff;
}

.line {
  display: none;
  @media (min-width: 1080px) {
   display: block;
  }
}

.lineListSecond li:nth-child(1) .line {
  display: none;

  @media (min-width: 1080px) {
    display: block;
    position: absolute;
    top: 56px;
    left: 265px;
    width: 110%;
    height: 130%;
    background: url(/images/aboutUs/line1.svg) center no-repeat;
  }
}

.lineListSecond li:nth-child(2) .line {
  display: none;

  @media (min-width: 1080px) {
    display: block;
    position: absolute;
    top: 56px;
    left: 190px;
    width: 71%;
    height: 120%;
    background: url(/images/aboutUs/line2.svg) center no-repeat;
  }
}

.lineListSecond li:nth-child(3) .line {
  display: none;

  @media (min-width: 1080px) {
    display: block;
    position: absolute;
    top: 56px;
    left: 250px;
    width: 71%;
    height: 120%;
    background: url(/images/aboutUs/line3.svg) center no-repeat;
  }
}

.lineListSecond li:nth-child(4) .line {
  display: none;

  @media (min-width: 1080px) {
    display: block;
    position: absolute;
    top: 56px;
    left: 210px;
    width: 71%;
    height: 120%;
    background: url(/images/aboutUs/line4.svg) center no-repeat;
  }
}

.lineListSecond li:nth-child(5) .line {
  display: none;

  @media (min-width: 1080px) {
    display: block;
    position: absolute;
    top: 40px;
    left: -380px;
    width: 160%;
    height: 120%;
    background: url(/images/aboutUs/line5.svg) center no-repeat;
  }

  @media (min-width: 1140px) {
    left: -349px;
  }
}

.lineListFirst li:nth-child(1) .line {
  display: none;

  @media (min-width: 1080px) {
    display: block;
    position: absolute;
    top: -36px;
    left: -225px;
    width: 85%;
    height: 300%;
    background: url(/images/aboutUs/line9.svg) center no-repeat;
  }
}

.lineListFirst li:nth-child(2) .line {
  display: none;

  @media (min-width: 1080px) {
    display: block;
    position: absolute;
    top: 71px;
    left: 32px;
    width: 71%;
    height: 120%;
    background: url(/images/aboutUs/line8.svg) center no-repeat;
  }
}

.lineListFirst li:nth-child(3) .line {
  display: none;

  @media (min-width: 1080px) {
    display: block;
    position: absolute;
    top: 72px;
    left: 0;
    width: 71%;
    height: 120%;
    background: url(/images/aboutUs/line7.svg) center no-repeat;
  }
}

.lineListFirst li:nth-child(4) .line {
  display: none;

  @media (min-width: 1080px) {
    display: block;
    position: absolute;
    top: 20px;
    left: 80px;
    width: 90%;
    height: 200%;
    z-index: 0;
    background: url(/images/aboutUs/line6.svg) center no-repeat;
  }
}